import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="micros"
export default class extends Controller {
  static targets = ['metaframework']

  frameworks = [
    'React',
    'Vue',
    'Solid',
    'Svelte',
    'Lit',
    'Qwik',
    'Preact',
    'Vanilla JavaScript',
    'Vanilla TypeScript',
  ]

  connect() {
    this.interval = setInterval(() => this.randomize(), 5000)
  }

  disconnect() {
    clearInterval(this.interval)
  }

  randomize() {
    const randomIndex = Math.floor(Math.random() * this.frameworks.length)
    this.metaframeworkTarget.textContent = this.frameworks[randomIndex]
  }
}
