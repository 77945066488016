import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="mobile-menu"
export default class extends Controller {
  static targets = ['mobileMenu', 'trigger']

  toggle() {
    const mobileMenu = document.getElementById('mobileMenu')
    mobileMenu.classList.toggle('hidden')
    mobileMenu.classList.toggle('flex')
    const mobileMenuBG = document.getElementById('mobileMenuBG')
    mobileMenuBG.classList.toggle('hidden')
    mobileMenuBG.classList.toggle('flex')
  }
}
