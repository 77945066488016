import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['menuholder', 'trigger']

  connect() {}

  toggle(e) {
    this.menuholderTarget.classList.toggle('hidden')
    this.menuholderTarget.classList.toggle('flex')
  }
}

/*
1. use <template />
  1.1 template in combination w/ browser native popover
2. portal-esque behavior w/ turbo stream
  - would need maybe a generic/catch all menu/* path ? 
  - if a menu exists at that endpoint, render it / stream it into <turbo_frame_tag "main_menu" /> or similar
  - ^^^ look into making this more generic so its more of a slot situation/ portal for menus... naaaaah!
*/
