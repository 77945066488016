import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="tabs"
export default class extends Controller {
  static targets = ['button', 'content']

  connect() {
    // Activate the first tab by default
    this.activateTab(this.buttonTargets[0].dataset.target)
  }

  activateTab(targetId) {
    this.buttonTargets.forEach((button) => {
      button.classList.toggle('current-tab', button.dataset.target === targetId)
    })

    this.contentTargets.forEach((content) => {
      content.classList.toggle('hidden', content.dataset.id !== targetId)
    })
  }

  switchTab(event) {
    const targetId = event.currentTarget.dataset.target
    this.activateTab(targetId)
  }
}
