import { Controller } from '@hotwired/stimulus'
import { post } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'earlyAccessModal',
    'trigger',
    'commsFormFields',
    'commsForm',
    'successMessage',
    'errorMessage',
  ]

  close() {
    const earlyAccessModal = document.getElementById('earlyAccessModal')
    earlyAccessModal.classList.add('hidden')
    earlyAccessModal.classList.remove('flex')
    this.resetForm()
  }

  open() {
    const earlyAccessModal = document.getElementById('earlyAccessModal')
    earlyAccessModal.classList.remove('hidden')
    earlyAccessModal.classList.add('flex')
  }

  async submitForm(event) {
    event.preventDefault()

    const response = await post(this.element.action, {
      body: new FormData(this.element),
      responseKind: 'json',
    })

    const data = await response.json

    if (data.status !== 'created') {
      const htmlError = data?.errors
        .map(
          (error) =>
            `<div class="text-red-500 w-full flex-col flex items-start justify-start font-semibold text-sm">${error}</div>`,
        )
        .join('')

      this.errorMessageTarget.innerHTML = htmlError
      return
    }

    this.successMessageTarget.innerHTML = `<div class="w-full flex-col flex items-start justify-start font-semibold text-sm text-foreground">${data.message}</div>`
    this.errorMessageTarget.innerHTML = ''
    this.commsFormFieldsTarget.classList.add('hidden')

    setTimeout(() => {
      this.resetForm()
      this.close()
    }, 5000)
  }

  resetForm() {
    const earlyAccessModal = document.getElementById('earlyAccessModal')
    const form = earlyAccessModal.querySelector('form')
    const commsFields = earlyAccessModal.querySelector(
      '[data-early-access-target="commsFormFields"]',
    )
    const successMessage = earlyAccessModal.querySelector(
      '[data-early-access-target="successMessage"]',
    )
    const errorMessage = earlyAccessModal.querySelector(
      '[data-early-access-target="errorMessage"]',
    )

    form.reset()
    commsFields.classList.remove('hidden')
    successMessage.innerHTML = ''
    errorMessage.innerHTML = ''
  }
}
